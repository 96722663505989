import React from "react";
import AppWrapper from "../layouts/AppWrapper";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { changeCurrentStep, setCurrentTourStep } from "../redux/user/userSlice";

export default function Overview() {
  const tour = useSelector((state) => state.tour.tour);
  const tourSteps = tour.tourSteps;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <AppWrapper>
      <ul className="flex flex-col justify-center gap-4 mt-4">
        {tourSteps.map((tourStep, i) => (
          <li
            onClick={() => {
              dispatch(changeCurrentStep(tourStep.position_nb));
              navigate(`/player`);
            }}
            className="flex items-center"
            key={i}
          >
            <img
              className="w-[76px] h-[76px] object-cover rounded-xl mr-6"
              src={tourStep.imageUrl}
              alt=""
            />
            <div className="flex flex-col items-start justify-center">
              <p className="p-0 text-[19px] font-semibold">
                {tourStep.position_nb}.{" "}
                <span className="uppercase">{tourStep.name}</span>
              </p>
              <p className="p-0 text-[17px] text-[#E67777]">
                Stop {tourStep.position_nb}
              </p>
            </div>
          </li>
        ))}
      </ul>

      <Link
        to={-1}
        className="inline-block text-lg font-normal w-full text-center px-5 py-3 bg-[#D54746] rounded-3xl text-white mt-8"
      >
        Continue
      </Link>
    </AppWrapper>
  );
}
