import React, { useEffect } from "react";
import AudioPlayer from "../Player/AudioPlayer";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

export default function Tour({ currentTourStep }) {
  const navigate = useNavigate();  
  useEffect(() => {
    if (!currentTourStep.name || currentTourStep === undefined) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <h1 className="text-center font-extrabold text-2xl mt-4">
        {currentTourStep?.name}
      </h1>
      <div className="pt-6">
        <div className="flex flex-col">
          <figure className="w-full h-[231px] mx-auto mb-6">
            <img
              src={currentTourStep?.imageUrl}
              alt=""
              className="w-full h-full  object-cover rounded-2xl"
            />
            <figcaption className="rounded-full bg-[#D54746] text-white w-fit px-[12px] py-[4px] mt-3 text-[12px]">
              {currentTourStep?.position_nb} stop
            </figcaption>
          </figure>

          <div className=" mb-8">
            <div className="pt-3 text-justify open-sans-300 text">
              {/* Text */}
              <div className="leading-relaxed">
                {currentTourStep?.text ? parse(currentTourStep?.text) : ''}
              </div>
            </div>
          </div>

          <div className="h-24"></div>

          <AudioPlayer />
        </div>
      </div>
    </>
  );
}
