import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentStep, setCurrentTourStep } from "../redux/user/userSlice";
import { fetchTour, setLoading } from "../redux/tour/tourSlice";
import IntroductionScreen from "../components/IntroductionScreen";
import LoadingScreen from "../components/LoadingScreen";

export default function Introduction() {
  const user = useSelector((state) => state.user);
  const tour = useSelector((state) => state.tour.tour);
  const { isLoading, progress, lang_code } = tour;
  const dispatch = useDispatch();

  const [loadingScreen, setLoadingScreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && loadingScreen) {
      navigate(`/player`);
    }
  }, [isLoading, loadingScreen]);

  useEffect(() => {
    if (lang_code !== "en") {
      dispatch(setLoading(true));
      dispatch(changeCurrentStep(1));
      dispatch(fetchTour("en"));
    }
  }, [dispatch, lang_code]);

  useEffect(() => {
    dispatch(
      setCurrentTourStep(
        tour.tourSteps.find(
          (tourStep) => parseInt(tourStep.position_nb) === user.currentStep
        )
      )
    );
  }, [tour, dispatch, user.currentStep, lang_code]);

  return (
    <>
      {loadingScreen ? (
        <LoadingScreen progress={progress} />
      ) : (
        <IntroductionScreen setLoadingScreen={setLoadingScreen} />
      )}
    </>
  );
  /*  return <>{isLoading ? <LoadingScreen progress={progress} /> : <IntroductionScreen />}</>; */
}
