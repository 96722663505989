import React from "react";
import { Link, useParams } from "react-router-dom";
import AppWrapper from "../layouts/AppWrapper";

export default function Feedback() {

  return (
    <AppWrapper>
      <div className="h-screen flex justify-center items-center">
        <div className="">
          <h1 className="text-black text-[20px] font-normal mb-6 text-center px-4 min-w-12">
            <p>How do you rate your overall experience using the artificial intelligence audio guide at Gediminas' Tower?</p>
          </h1>
          <div className="flex items-center justify-between w-[250px] m-auto">
            {/* 1 */}
            <Link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 36 36"
                fill="none"
              >
                <g clipPath="url(#clip0_191_646)">
                  <path
                    d="M36 18C36 27.941 27.941 36 18 36C8.06 36 0 27.941 0 18C0 8.06 8.06 0 18 0C27.941 0 36 8.06 36 18Z"
                    fill="#FFCC4D"
                  />
                  <path
                    d="M11.5 20C12.8807 20 14 18.433 14 16.5C14 14.567 12.8807 13 11.5 13C10.1193 13 9 14.567 9 16.5C9 18.433 10.1193 20 11.5 20Z"
                    fill="#664500"
                  />
                  <path
                    d="M24.5 20C25.8807 20 27 18.433 27 16.5C27 14.567 25.8807 13 24.5 13C23.1193 13 22 14.567 22 16.5C22 18.433 23.1193 20 24.5 20Z"
                    fill="#664500"
                  />
                  <path
                    d="M23.4851 27.879C23.4741 27.835 22.3401 23.5 18.0001 23.5C13.6601 23.5 12.5261 27.835 12.5151 27.879C12.4621 28.092 12.5591 28.31 12.7471 28.423C12.9351 28.535 13.1801 28.509 13.3431 28.363C13.3521 28.356 14.3561 27.5 18.0001 27.5C21.5901 27.5 22.6171 28.33 22.6561 28.363C22.7511 28.454 22.8751 28.5 23.0001 28.5C23.0841 28.5 23.1691 28.479 23.2461 28.436C23.4421 28.325 23.5401 28.097 23.4851 27.879Z"
                    fill="#664500"
                  />
                  <path
                    d="M10 30C10 32.762 7.762 35 5 35C2.238 35 0 32.762 0 30C0 27.238 4 20 5 20C6 20 10 27.238 10 30Z"
                    fill="#5DADEC"
                  />
                  <path
                    d="M30 12.9999C24.446 12.9999 22.198 8.6329 22.105 8.4469C21.858 7.9529 22.058 7.3519 22.552 7.1049C23.044 6.8579 23.644 7.0569 23.892 7.5479C23.967 7.6939 25.713 10.9999 30 10.9999C30.553 10.9999 31 11.4479 31 11.9999C31 12.5529 30.553 12.9999 30 12.9999ZM6 12.9999C5.448 12.9999 5 12.5519 5 11.9999C5 11.4479 5.448 10.9999 6 10.9999C11.083 10.9999 11.996 7.8799 12.033 7.7469C12.178 7.2189 12.723 6.8989 13.252 7.0379C13.782 7.1769 14.103 7.7109 13.97 8.2429C13.921 8.4369 12.704 12.9999 6 12.9999Z"
                    fill="#664500"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_191_646">
                    <rect width="36" height="36" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
            {/* 2 */}
            <Link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 37 36"
                fill="none"
              >
                <path
                  d="M36.75 18C36.75 27.941 28.691 36 18.75 36C8.81 36 0.75 27.941 0.75 18C0.75 8.06 8.81 0 18.75 0C28.691 0 36.75 8.06 36.75 18Z"
                  fill="#FFCC4D"
                />
                <path
                  d="M12.25 18C13.6307 18 14.75 16.433 14.75 14.5C14.75 12.567 13.6307 11 12.25 11C10.8693 11 9.75 12.567 9.75 14.5C9.75 16.433 10.8693 18 12.25 18Z"
                  fill="#664500"
                />
                <path
                  d="M25.25 18C26.6307 18 27.75 16.433 27.75 14.5C27.75 12.567 26.6307 11 25.25 11C23.8693 11 22.75 12.567 22.75 14.5C22.75 16.433 23.8693 18 25.25 18Z"
                  fill="#664500"
                />
                <path
                  d="M9.41511 27.871C9.59311 28.032 9.85911 28.042 10.0501 27.9C10.0891 27.871 13.9721 25 18.7501 25C23.5161 25 27.4121 27.871 27.4501 27.9C27.6411 28.042 27.9071 28.03 28.0851 27.871C28.2621 27.711 28.3021 27.447 28.1791 27.243C28.0501 27.029 24.9621 22 18.7501 22C12.5381 22 9.44911 27.028 9.32111 27.243C9.19811 27.448 9.23711 27.711 9.41511 27.871Z"
                  fill="#664500"
                />
              </svg>
            </Link>
            {/* 3 */}
            <Link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 37 36"
                fill="none"
              >
                <path
                  d="M36.5 18C36.5 27.941 28.441 36 18.5 36C8.56 36 0.5 27.941 0.5 18C0.5 8.06 8.56 0 18.5 0C28.441 0 36.5 8.06 36.5 18Z"
                  fill="#FFCC4D"
                />
                <path
                  d="M12 20C13.3807 20 14.5 18.433 14.5 16.5C14.5 14.567 13.3807 13 12 13C10.6193 13 9.5 14.567 9.5 16.5C9.5 18.433 10.6193 20 12 20Z"
                  fill="#664500"
                />
                <path
                  d="M25 20C26.3807 20 27.5 18.433 27.5 16.5C27.5 14.567 26.3807 13 25 13C23.6193 13 22.5 14.567 22.5 16.5C22.5 18.433 23.6193 20 25 20Z"
                  fill="#664500"
                />
                <path
                  d="M25.5 26H11.5C10.948 26 10.5 25.553 10.5 25C10.5 24.447 10.948 24 11.5 24H25.5C26.053 24 26.5 24.447 26.5 25C26.5 25.553 26.053 26 25.5 26Z"
                  fill="#664500"
                />
              </svg>
            </Link>
            {/* 4 */}
            <Link to="/">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 37 36"
                fill="none"
              >
                <path
                  d="M18.25 36C28.1911 36 36.25 27.9411 36.25 18C36.25 8.05887 28.1911 0 18.25 0C8.30887 0 0.25 8.05887 0.25 18C0.25 27.9411 8.30887 36 18.25 36Z"
                  fill="#FFCC4D"
                />
                <path
                  d="M10.7649 23.621C10.8099 23.8 11.9329 28 18.2499 28C24.5679 28 25.6899 23.8 25.7349 23.621C25.7899 23.404 25.6919 23.179 25.4979 23.067C25.3029 22.956 25.0589 22.989 24.8979 23.144C24.8789 23.163 22.9439 25 18.2499 25C13.5559 25 11.6199 23.163 11.6019 23.145C11.5059 23.05 11.3779 23 11.2499 23C11.1659 23 11.0809 23.021 11.0039 23.064C10.8079 23.176 10.7099 23.403 10.7649 23.621Z"
                  fill="#664500"
                />
                <path
                  d="M12.25 17C13.6307 17 14.75 15.433 14.75 13.5C14.75 11.567 13.6307 10 12.25 10C10.8693 10 9.75 11.567 9.75 13.5C9.75 15.433 10.8693 17 12.25 17Z"
                  fill="#664500"
                />
                <path
                  d="M24.25 17C25.6307 17 26.75 15.433 26.75 13.5C26.75 11.567 25.6307 10 24.25 10C22.8693 10 21.75 11.567 21.75 13.5C21.75 15.433 22.8693 17 24.25 17Z"
                  fill="#664500"
                />
              </svg>
            </Link>
            {/* 5 */}
            <Link to="/">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  d="M36 18C36 27.941 27.941 36 18 36C8.059 36 0 27.941 0 18C0 8.059 8.059 0 18 0C27.941 0 36 8.059 36 18Z"
                  fill="#FFCC4D"
                />
                <path
                  d="M17.9999 21.8491C15.0339 21.8491 13.0649 21.5031 10.6309 21.0301C10.0739 20.9241 8.99292 21.0301 8.99292 22.6681C8.99292 25.9431 12.7559 30.0371 17.9999 30.0371C23.2439 30.0371 27.0069 25.9431 27.0069 22.6681C27.0069 21.0301 25.9249 20.9231 25.3689 21.0301C22.9349 21.5031 20.9669 21.8491 17.9999 21.8491Z"
                  fill="#664500"
                />
                <path
                  d="M16.6499 3.28109C15.7909 0.850085 13.1259 -0.425915 10.6939 0.431085C9.21789 0.951085 8.17289 2.14209 7.76589 3.53509C6.57489 2.70609 5.01489 2.43509 3.54089 2.95509C1.11089 3.81309 -0.167107 6.48009 0.691893 8.91109C0.813893 9.25508 0.975893 9.57409 1.16389 9.86909C3.11489 13.4511 8.75189 15.9691 12.1649 16.0001C14.8019 13.8331 17.6109 8.33508 16.8829 4.32308C16.8449 3.97508 16.7699 3.62509 16.6499 3.28109ZM19.3499 3.28109C20.2089 0.850085 22.8749 -0.425915 25.3059 0.431085C26.7819 0.951085 27.8269 2.14209 28.2349 3.53509C29.4259 2.70609 30.9859 2.43509 32.4599 2.95509C34.8899 3.81309 36.1669 6.48009 35.3099 8.91109C35.1869 9.25508 35.0259 9.57409 34.8369 9.86909C32.8859 13.4511 27.2489 15.9691 23.8349 16.0001C21.1979 13.8331 18.3899 8.33508 19.1179 4.32308C19.1549 3.97508 19.2299 3.62509 19.3499 3.28109Z"
                  fill="#DD2E44"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </AppWrapper>
  );
}
