import { router } from "./Router.jsx";
import { RouterProvider } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from "react";

function App() {
  return <RouterProvider router={router} />;
}

export default App;
