import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const ProtectedRoutes = ({ children }) => {
  const { language_code } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const { isTicketPurchased, currentStep } = user;

  useEffect(() => {
    if (!isTicketPurchased && currentStep > 1) {
      navigate(`/ticket`);
    }
  }, [navigate, user, currentStep, isTicketPurchased, language_code]);

  return children;
};

export default ProtectedRoutes;
